html, body {
  text-size-adjust: none; 
}

.container {
    width: 100%;
    display: flex;
    height: 100%;
    text-align: center;
    align-items: center;
    /* background: #071534; */
    flex-direction: column;
    padding-bottom: 150px;
  }

  .priceAlert{
    width: 90%;
    height: 40px;
    border-radius: 20px;
    border: 2px solid #228b22;
    background: #cff4cf;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 50px;
    margin-bottom: 10px;
  }
  
  .alertHeading {
    font-size: 18px;
    font-weight: 600;
    color: #228b22;
  }

  .priceBox {
    width: 90%;
    display: flex;
    height: 130px;
    margin-top: 20px;
    margin-bottom: 10px;
    border-radius: 30px;
    flex-direction: row;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.26);
    /* background:#662ec7; */
    /* background: linear-gradient(45deg, #662ec7 30%, #6050dc 85%); */
    background: linear-gradient(90deg, #007bff95 5%, #6050dc90 90%);
  }

  .priceBoxLeft {
    width: 46%;
    /* background: red; */
    display: flex;
    padding-left: 8%;
    padding-top: 18px;
    align-items: flex-start;
    flex-direction: column;
  }

  .timeHeading {
    font-size: 30px;
    font-weight: 600;
    color: #d2edff;
  }

    
  .perDayHeading {
    opacity: 0.75;
    margin-top: -8px;
    font-size: 18px;
    font-weight: 600;
    color: #E8f5FE;
  }

  .priceBoxRight {
    display: flex;
    margin-bottom: 6px;
    padding-top: 24px;
    align-items: baseline;
    justify-content: center;
    /* background: red; */
  }

  .newPrice {
    font-size: 34px;
    margin-top: 15px;
    color: #E8f5FE;
    margin-right: 8px;
    font-weight: bold;
  }
  
  .oldPrice {
    opacity: 0.8;
    font-size: 20px;
    color: #d2edff;
    font-weight: bold;
    text-decoration: line-through;
    vertical-align: sub;
  }

  .benefits{
    width: 100%;
    font-size:28px;
    font-weight: 300;
    color: #ffffffe2;
    text-align: center;
    margin-top: 30px;
  }


  .featureContainer{
    width: 90%;
    display: flex;
    text-align: left;
    padding:25px 0px;
    margin-top: 10px;
    border-radius: 30px;
    background: #f0f0f045;
    flex-direction: column;
    /* border: 2px solid #6050dc; */
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.26);
  }

  .icon{
    top: -2px;
    font-size: 24px;
    color: #2eb22ee5;
    margin-right: 10px;
    position: relative;
  }
  
  .feature{
    padding:0px;
    display: flex;
    margin-left: 25px;
    margin-right: 20px;
  }
  
  .featureHeading {
    font-size: 19px;
    font-weight: 300;
    color: #ffffffd8;
    margin-left: 5px;
  }
  

  .mobileDownloadContainer{
    bottom: 0;
    width: 100%;
    z-index: 1000;
    display: flex;
    padding: 12px 0;
    position: fixed;
    background:#071534;
    align-items: center;
    justify-content: center;
  }
  
  .mobileDownloadButton{
    width: 90%;
    display: flex;
    padding: 2px 0;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    /* background: linear-gradient(90deg, #32cd32 60%, #adebad 85%); */
    background: #32cd32;
  }


  .upgradeHeading{
    width: 100%;
    color: #fff;
    font-size:22px;
    font-weight: 300;
    text-align: center;
  }