body {
  margin: 0;
  padding: 0;
  color: #fff;
  line-height: 1.6;
  background-color: rgb(0, 0, 0);
  font-family: 'Metropolis', sans-serif;
}


/* Importing the Metropolis font */
@font-face {
  font-style: normal;
  font-family: 'Metropolis';
  src: url('./fonts/Metropolis-Regular.otf') format('opentype');
}

@font-face {
  font-style: normal;
  font-family: 'Metropolis Bold';
  src: url('./fonts/Metropolis-Bold.otf') format('opentype');
}

@font-face {
  font-style: normal;
  font-family: 'Metropolis SemiBold';
  src: url('./fonts/Metropolis-SemiBold.otf') format('opentype');
}


/* Common Font Styles */
/* TODO => Improve this styling accross the website */
h1, h2, h3, h4, p {
  padding:0;
  margin-block-end: 0.5rem;
  margin-block-start: 0.5rem;
  font-weight: 500
}


/* Headings Text */
h1 {
  font-size: 72px;
  font-weight: bold;
  font-family: 'Metropolis SemiBold', sans-serif;
}
h2 {
  padding:0;
  font-size: 48px;
  font-family: 'Metropolis SemiBold', sans-serif;
}
h3{
  font-size: 36px;
  font-family: 'Metropolis SemiBold', sans-serif;
}
h4{
  margin: 5px;
  font-size: 20px;
  letter-spacing: 2px;
  font-family: 'Metropolis', sans-serif;
}

/* Paragraph Text */
p {
  font-size: 16px;
  margin-bottom: 1rem;
  font-family: 'Metropolis', sans-serif;
}


/* Font Sizes for Mobile Devices */
@media (max-width: 768px) {
  h1 {
    font-size: 60px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 36px;
  }
  h4 {
    font-size: 16px;
  }
  p {
    font-size: 12px;
  }
}